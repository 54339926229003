import { Container, Section } from "../Markup";
import Img from "gatsby-image";
import React from "react";

const PictureSection = ({pictures = []}) => {
  if (!Array.isArray(pictures) || !pictures.length) {
    return null
  }

  return (
    <Section>
      <Container>
        <div className="columns is-multiline is-variable is-4-tablet is-6-desktop is-8-widescreen">
          {pictures.map(({image: {childImageSharp: {fluid}}}, index) => (
            <div className="column is-half-tablet is-one-third-desktop" key={index}>
              {fluid && <Img fluid={fluid} fadeIn={false} loading="eager"/>}
            </div>
          ))}
        </div>
      </Container>
    </Section>
  )
};

export default PictureSection;
