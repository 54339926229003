import React from 'react'
import { graphql } from 'gatsby'
import BannerHeader from "../components/BannerHeader";
import Layout from "../components/Layout";
import PictureSection from "../components/ProjectPage/PictureSection";
import MediaPreview from "../components/ProjectPage/MediaPreview";
import { HTMLContent } from '../components/Content'
import { Section, Container } from "../components/Markup";
import ButtonLink from "../components/ProjectPage/ButtonLink";


const ProjectPage = (
  {
    data: {
      markdownRemark: {
        html,
        frontmatter: {
          banner_image,
          banner_image_position_y: bannerImagePositionY,
          button_link: buttonLink,
          embed_video_url: embedVideoUrl,
          main_image,
          pictures,
          subtitle,
          title
        }
      }
    }
  }
) => {

  // note: next two lines helps avoid destructing from null
  const bannerImage = banner_image || {};
  const mainImage = main_image || {};

  return (
    <Layout fullWidth>

      <BannerHeader image={bannerImage} imagePositionY={bannerImagePositionY} />

      <Section>
        <Container>
          <h1 className="is-size-2 is-bold">{title}</h1>
          <div className="is-size-4">{subtitle}</div>
        </Container>
      </Section>

      <Section>
        <Container>
          <div className="columns">
            <div className="column">
              <MediaPreview mainImage={mainImage} embedVideoUrl={embedVideoUrl}/>
              {html && <HTMLContent className="content is-size-4" content={html}/>}
              <ButtonLink buttonLink={buttonLink} className="has-text-centered"/>
            </div>
          </div>
        </Container>
      </Section>

      <PictureSection pictures={pictures}/>

    </Layout>)
};

export default ProjectPage

export const pageQuery = graphql`
  query ProjectByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        subtitle
        banner_image_position_y
        button_link
        main_image {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        banner_image {
          childImageSharp {
            fluid(maxWidth: 800, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        link
        embed_video_url
        pictures {
          image {
            childImageSharp {
              fluid(maxWidth: 700, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;

