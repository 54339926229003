import React from 'react';
import { withBreakpoints } from "gatsby-plugin-breakpoints";
import Img from "gatsby-image";
import styled from "styled-components";

const DesktopMediaHolder = styled.div`
  float: left;
  min-width: 60%;
  margin-right: 3rem;
`;

const appendDefaultYoutubeParams = (originalUrl) => {
  let str = originalUrl;
  if (str.includes('youtube.com')) {
    try {
      const baseParams = new URLSearchParams('autoplay=1&controls=0&fs=0&rel=0&showinfo=0&iv_load_policy=3');
      const url = new URL(str);
      const urlParams = new URLSearchParams(url.search);
      for (let param of urlParams) {
        baseParams.set(param[0], param[1])
      }
      const queryString = baseParams.toString();
      if (queryString.length > 0) {
        url.search = `?${queryString}`
      }
      str = url.toString();
    } catch (e) {
      return originalUrl
    }
  }

  return str
};

const MediaPreview = (
  {
    mainImage,
    embedVideoUrl,
    breakpoints: {mobile, tablet, desktop}
  }) => {
  const {childImageSharp: {fluid} = {childImageSharp: {}}} = mainImage;
  const isMobile = mobile || (tablet && !desktop);

  const media = () => {
    switch (true) {
      case Boolean(embedVideoUrl):
        let src = appendDefaultYoutubeParams(embedVideoUrl);
        return (
          <figure className="image is-16by9">
            <iframe
              className="has-ratio"
              width="640"
              height="360"
              src={src}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen />
          </figure>);
      case Boolean(fluid):
        return <Img fadeIn={false} loading="eager" fluid={fluid} />;
      default:
        return null
    }
  };
  const currentMedia = media();

  if (!currentMedia) {
    return null
  }

  return (
    <div className="mb-5">
      {isMobile ? currentMedia : <DesktopMediaHolder>{currentMedia}</DesktopMediaHolder>}
    </div>
  );
};

export default withBreakpoints(MediaPreview);
