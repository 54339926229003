import React from 'react';

const ButtonLink = ({buttonLink, className = null}) => {
  const [buttonLabel, buttonPath] = (buttonLink || '').split(';');

  if (!buttonLabel || !buttonPath) {
    return null
  }

  return (
    <div className={className}>
      {buttonLabel && buttonPath && <a className="button" href={buttonPath}>{buttonLabel}</a>}
    </div>
  );
};

export default ButtonLink;